<template>
  <div class="body1">
    <a-breadcrumb>
      <a-breadcrumb-item>
        <router-link :to="{name: 'home'}">{{ $t('home') }}</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        {{ $t('booking_rules') }}
      </a-breadcrumb-item>
    </a-breadcrumb>
    <div style="margin: 30px 10px;">
      <template v-if="isZhCn">
        <h1 id="几度预订规则">几度预订规则</h1>
        <h2>• 预订方式</h2>
        <p>我们为您提供多渠道预订服务：</p>
        <p>1）网上预订： https://www.jidutravel.com/home </p>
        <p>2）微信小程序预订：几度旅行</p>
        <h2>• 预订服务时间</h2>
        <p>网站、微信小程序7*24小时接受预订；服务时间：9:00-18:00，紧急到店问题7*24小时。</p>
        <h2>• 如何获得订单确认通知</h2>
        <p>网站预订/微信小程序预订：我们会向您所填的邮箱发送邮件确认。</p>
        <h2>• 预订变更与取消</h2>
        <p>如您需要变更或取消订单，请根据订单中酒店对应的取消条款，在网站或小程序查询订单并进行变更或取消。</p>
        <p>订单在确认后均有免费取消期限，如在免费期限后更改、取消或未入住，酒店可能会拒绝。</p>
        <p>备注：酒店预订的变更与取消政策，以所订酒店具体规定为准；不同酒店或同一家酒店在不同时间的相关规定可能有差异。</p>
        <h2>• 关于房价</h2>
        <p>几度旅行展示的价格不包含酒店其它税费(如：政府税、城建税等)。</p>
        <h2>• 订单确认后办理入住问题</h2>
        <p>若预订已得到几度旅行确认，客人办理入住时出现问题时（如前台无预订、酒店无房、无法安排已确认房型的房间以及价格出入等），请及时联系几度旅行紧急问题处理中心+86-411-82820640</p>
        <h2>• 避免重复预订</h2>
        <p>若您通过多种方式在几度旅行预订了相同时间相同姓名的同一家酒店，您最终只能保留一个有效预订，请及时取消其它预订，避免出现重复。</p>
      </template>
      <template v-else>
        <h1>Booking Rules Of JiduTravel</h1>
        <h2>• How to book</h2>
        <p>We provide you with multi-channel booking services:</p>
        <p>1) Online booking: https://www.jidutravel.com/home</p>
        <p>2) WeChat mini program booking: several trips</p>
        <h2>• Book service hours</h2>
        <p>The website and WeChat mini program accept reservations 7*24 hours; service time: 9:00-18 :00 , emergency arrival problems 7* 24 hours .</p>
        <h2>• How to get an order confirmation notification</h2>
        <p>Website reservation/ WeChat mini program reservation: We will send an email confirmation to the email address you filled in.</p>
        <h2>• Reservation Changes and Cancellations</h2>
        <p>If you need to change or cancel the order, please check the order on the website or mini program and make changes or cancellations according to the cancellation terms of the hotel in the order.</p>
        <p>a free cancellation period after confirmation , and the hotel may refuse any changes, cancellations or no-shows after the free period .</p>
        <p>Remarks: The changes and cancellation policies of hotel reservations are subject to the specific regulations of the hotel booked; the relevant regulations of different hotels or the same hotel at different times may be different.</p>
        <h2>• About room rates</h2>
        <p>several trips do not include other hotel taxes (such as government taxes, urban construction taxes, etc.).</p>
        <h2>• Check in problem after booking confirmation</h2>
        <p>If the reservation has been confirmed by several trips , if there is a problem during check-in (such as no reservation at the front desk, no room in the hotel, unable to arrange the room of the confirmed room type, price difference, etc.), please contact the emergency problem handling center of several trips in time+ 86-411-82820640</p>
        <h2>• Avoid double bookings</h2>
        <p>several trips in multiple ways , you can only keep one valid reservation in the end. Please cancel other reservations in time to avoid duplication.</p>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "BookingRules",
  data(){
    return {
      isZhCn: this.$store.getters["setting/getLanguage"] === 'zh_CN',
    }
  }
}
</script>

<style scoped>

</style>